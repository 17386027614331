.dropdown {
	position: relative;
	display: inline-block;
	margin: 1rem 0 0 1rem;
}

.input {
	width: 300px;
	height: 15px;
	border: 1.5px solid var(--secondary-color);
	color: #d4d4d4;
	display: flex;
	align-items: center;
	border-radius: 5px;
	font-size: 13px;
	padding: 12px;
	margin:0.5rem 1rem 0 1rem;
	background: url("../../assets/images/down_arrow.svg") no-repeat right center;
	background-size: 35px;
	cursor: pointer;
}

.inputLabel{
	margin: 1rem 0 0 1.2rem;
	font-weight: bold;
	color: #4b4d56;
}

.dropdownContent {
	display: none;
	position: absolute;
	z-index: 10;
	max-height: 200px;
	overflow-y: scroll;
	margin-left: 1rem;
	background-color: #ffffff;
	width: 330px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropdownContent {
	display: block;
}

.dropdownContent div {
	color: black;
	padding: 7px;
}

.dropdownContent div:hover {
	background-color: var(--accent-color);
}

.subDropdownContent {
	display: none;
	padding: 1rem;
	gap: 0.2rem;
	width: 200px;
	position: absolute;
	left: 345px;
	top: 70px;
	background-color: #ffffff;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	z-index: 20;
}

.dropdownContent:hover ~ .subDropdownContent {
	display: block;
}

.subDropdownContent:hover { 
	display: block; 
  }

.materialName {
	height: 27px;
	padding: 3px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.materialName span {
	margin-left: 5px;
}

.subDropdownContent div:hover {
	background-color: var(--accent-color);
}

.hover {
	padding: 10px;
	cursor: pointer;
}

.noAlloysFound span:hover{
	background-color: transparent;
}

.tableContainer {
	width: 95%;
	max-height: 210px;
	margin-top: 20px;
	margin-left: 1.8rem;
	overflow-y: auto;
}

.table {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #ccc;
}

.table thead {
	position: sticky;
	top: 0;
	background: var(--secondary-color);
	color: #ffffff;
	z-index: 5;
}

.table thead th {
	font-weight: 100;
	font-size: 16px;
	height: 30px;
	border: none;
}

.table th,
.table td {
	padding: 10px;
	border: 1px solid #ccc;
	text-align: left;
	border: none;
}

.table tbody tr {
	height: 30px;
	border-bottom: 1px solid #ccc;
	margin-left: 0.5rem;
}

.table tbody tr:hover {
	background-color: var(--accent-color);
}


.table td:nth-child(1){
	width: 450px;
}


.costTableHeader{
	display: flex;
	align-items: center;
	margin-left: 1.5rem;
	gap:10px
}

.costTableInfoButton {
	margin-top: 4px;
	position: relative;
	display: inline-block;
  }
  
  .costTableInfoButton .tooltipText {
	visibility: hidden;
	width: 100px;
	background-color: whitesmoke;
	color: var(--secondary-color);
	text-align: center;
	border-radius: 5px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	border: 1px solid var(--secondary-color);
	font-style: italic;
	font-size: 12px;
	margin-left: 5px;
	opacity: 1;
	transition: opacity 0.3s;
  }
  
  .costTableInfoButton:hover .tooltipText {
	visibility: visible;
	opacity: 1;
  }

.inputCost {
	width: 85px;
	height: 22px;
	text-align: center;
	padding: 2px 2px 2px 5px;
	border-radius: 0 2px 2px 0;
	border: 1px solid #cdcdcd;
	background: transparent;
}


.currencySymbol {
	width: 30px;
	border: 1px solid #cdcdcd;
	border-radius: 2px 0 0 2px;
	border-right: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
}

.bottomContainer {
	display: flex;
	margin: 1rem;
	gap: 1rem;
	position: absolute;
	bottom: 0rem;
	right: 2rem;
}

.deleteButton {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.popUp{
	text-decoration: underline;
	color: #00f;
	font-size: 16px;
	cursor: pointer;
}


.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
  }
  
  .dialog {
	position: relative;
	background: white;
	padding: 20px;
	border-radius: 8px;
	height: 400px;
	width: 900px;
	z-index: 1001;
  }
  
  .blurred {
	filter: blur(5px);
  }
  
.closeButton {
	position: absolute;
	right: 0.6rem;
	top: 1rem;
	background: none;
	border: none;
	font-size: 16px;
	cursor: pointer;
}


.dialogTableContainer{
	width: 98%;
	max-height: 350px;
	margin-top: 2.5rem;
	margin-left: 1.5rem;
	overflow-y: auto;
}

.errorMessage{
    margin-top: 0.3rem;
    margin-left: 2.3rem;
    font-size: 14px;
	font-weight: 500;
    color:#D12323;
}


.deleteOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}
  
.deleteDialog {
	position: relative;
	background: white;
	padding: 1.5rem;
	border-radius: 8px;
	height: 130px;
	width: 300px;
	z-index: 10;
}

.deleteWarningIcon{
	display: flex;
	gap: 5px;
}

.deleteWarningIcon span{
	font-weight: 550;
	margin-top: 3px;
	font-size: 18px;
}


.buttonsContainer{
	bottom:5rem;
    display: flex;
    gap:15px
}

.buttonsContainer button{
    width: 140px;
    height: 40px;
    font-size: 15px;
    border: none;
    border-radius: 4px;
	cursor: pointer;
}

