.headerContainer img{
    margin: 0 0 0.3rem 0.5rem;
    height: 30px;
    width: 150px;
    
}

.headerLine{
    height:1px;
    width: 100%;
    background-color: #efeff0;
}