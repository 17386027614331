.mainContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
    height: 530px;
    margin: 2rem 0 0 2rem;
    font-size: 14px;
    position: relative;
}
.machineCardsContainer{
    height: 450px;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 130px;
    width: 400px;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    box-sizing: border-box;
    border-radius: 5px;
}

.machineNameContainer{
     display: flex;
     gap:1rem
}
.machineName{
    height: 30px;
    width: 230px;
    border:none;
    margin-bottom: 5px;
    font-weight: 550;
}

.mhrInputContainer{
     display: flex;
     align-items:center;
     gap:25px;
}

.mhrCost{
    height: 30px;
    width: 150px;
    border:1.5px solid var(--secondary-color);
    border-radius:5px ;
    margin-bottom: 10px;
    font-weight: 550;
    padding-left: 20px;
}

.currencySymbol{
    height: 30px;
    width: 20px;
    padding: 2px;
    color:var(--secondary-color);
    font-weight: 500;
    border-radius: 7px 0px 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: transparent;
}


.calculateMhrButton{
    height: 40px;
    width: 150px;
    color: var(--secondary-color);
    border:1.5px solid var(--secondary-color);
    background-color: #ffffff;
    border-radius: 5px;
    font-weight: 550;
}

.calculateMhrButton:before {
    height: 0%;
    width: 2px;
}
.calculateMhrButton:hover {
    border: none;
    color: #ffffff;
    background-color: var(--primary-color);
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .5), 
      inset -4px -4px 6px 0 rgba(255,255,255,.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
}


.infoButton{
    display: flex;
    align-items: center;    
    gap: 6px;
    margin-top: 10px;
    font-size: 12px;
    color: gray;
    font-style: italic;
}

.infoButton img{
    height: 13px;
    width: 13px;
    color: gray;
}

.mhrCalculator{
    height: 438px;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin: 0 2rem 2rem 0;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}
  
.dialog {
	position: absolute;
	background: white;
	padding: 20px;
	border-radius: 8px;
	height: 100px;
	width: 300px;
	z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    font-size: 16px;
}

.content button{
    background: transparent;
    border: none;
}

.content button img{
    height: 15px;
    width: 15px;
}

.totalCost{
    font-size: 20px;
    font-weight: 550;
    color: var(--secondary-color);
}

.closeButton {
	position: absolute;
	right: 0.6rem;
	top: 1rem;
	background: none;
	border: none;
	font-size: 16px;
	cursor: pointer;
}

.bottomContainer{
	position: absolute;
    display: flex;
    gap: 1rem;
	bottom: 2rem;
	right: 0rem;
}

.copiedMessage {
    position: absolute;
    top: 100px; 
    right: 20px;
    background-color: gray ; 
    color: white;
    padding: 10px;
    font-size: 10px;
    border-radius: 5px;
    z-index: 100; 
    animation: fadeInOut 2s forwards;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  