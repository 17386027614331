.backButton{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 1rem;
}

.backButtonIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    height:35px;
    width:35px;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
}

.backButtonIcon:hover{
    background-color: #fffad8;;
}

.backButtonIcon img{
    height: 10px;
    width: 21px;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}
  
.dialog {
	position: relative;
	background: white;
	padding: 1.5rem;
	border-radius: 8px;
	height: 130px;
	width: 300px;
	z-index: 100;
}
  
.blurred {
	filter: blur(5px);
}
  
.exitOnboardingIcon{
    display: flex;
    align-items: center;
    gap:5px;
}

.exitOnboardingIcon img{
    width: 20px;
    height: 20px;
}

.exitOnboardingIcon span{
    font-weight: 550;
    margin-top: 2px;
}

.buttonsContainer{
    display: flex;
    gap:15px
}

.buttonsContainer button{
    width: 140px;
    height: 40px;
    font-size: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

