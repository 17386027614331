.mainContent{
    height: 250px;
    width: 650px;
    color: #4b4b4d;
    margin: 2rem 1rem 1rem 20rem;
}

.onboardingTxt{
    text-align: center;
    font-size: 15px;
}

.dropdownContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    margin-left: 1rem;
    text-align: left;
}

.dropdownContainer select {
    margin-right: 10px;
    height: 40px;
    width: 450px;
    border-radius: 5px;
    border: 1px solid #12889A;
    padding: 10px;
    appearance: none;
    background: url('../../assets/images/down_arrow.svg') no-repeat right center;
    background-size: 35px;
    background-color: transparent; 
    font-size: 15px;
    cursor: pointer;
}

.dropdownContainer select:active, .dropdownContainer select:focus{
    border: 1.5px solid #12889A;
    outline: none;
}

.dropdownContainer label {
    display: block;
    margin-bottom: 10px;
    font-weight: 550;
}

.dropdownContainer  select option:hover{
    background-color: var(--secondary-color);
}

.radioContainer{
    margin-left: 20px;

}

.radioContainer p{
    font-weight: 550;
}


.radioOptionsContainer{
    height:100px;
    width: 430px;
    display: flex;
    font-size: 13px;
    flex-direction: column;
    padding: 0.5rem;
    gap:5px;
    border-radius: 5px;
    border: 1px solid #12889A;

}

.placeholderOption {
    color: grey;
}

.placeholderSelected {
    color: grey;
}

.errorMessage{
    margin-top: 3px;
    margin-left: 7px;
    font-size: 12px;
    color:#D12323;
}

.options{
    color: black;
}
.options:focus{
    background-color: var(--secondary-color);
}

.nextButtonContainer{
    position: absolute;
    bottom: 1.3rem;
    right: 5rem;
}




