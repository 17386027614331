.mhrContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    z-index: 10; 
    padding: 16px; 
}

.mhrHeaderContainer span{
    font-size: 17px;
    font-weight: 550;
    color: var(--secondary-color);
}


.inputGroup {
    padding: 15px 0 0 0;
    font-size: 16px;
    margin-left: 15px;
}

.inputGroup label {
    font-size: 14px;
}
  
.mhrInput {
    width: 75%;
    height: 35px;
    font-size: 16px;
    text-align: left;
    margin-top: 7px;
    border: 1.5px solid var(--secondary-color);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fixedCostHeading {
    font-size: 17px;
    font-weight: 550;
    color: var(--secondary-color);
}

.fixedCostContainer{
    margin-bottom: 30px;
}

.operatingCostHeading{
    font-size: 17px;
    font-weight: 550;
    color: var(--secondary-color);
}

.currencySymbol {
    height: 33px;
    width: 25px;
    padding: 2px;
    color:var(--secondary-color);
    margin-top: 8px;
    font-weight: 500;
    border-radius: 7px 0px 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: transparent;
}
  
.unit {
    height: 33px;
    width: 30px;
    padding: 2px;
    color: var(--secondary-color);
    margin-top: 5px;
    font-weight: 500;
    border-radius: 7px 0px 0px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: transparent;
}

.calculate {
    font-size: 1rem;
    height: 40px;
    width: 150px;
    border-radius: 5px;
    margin-left: 25%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: var(--primary-color);
    border: none;
    color: white;
}

.selectMachineContainer{
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    margin-left: 15px;
    margin-bottom: 2rem;
}


.selectMachineContainer span{
    color: black;
    font-weight: 500;
    font-size: 14px;
}

.selectMachineContainer select{
    width: 75%;
    height: 35px;
    font-size: 14px;
    text-align: left;
    margin-top: 7px;
    border: 1.5px solid var(--secondary-color);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
}


.placeholderOption {
    color: grey;
}

.errorMessage{
    margin-top: 5px;
    margin-left: 7px;
    font-size: 12px;
    color:#D12323;
}

.options{
    color: black;
    font-size: 14px;
}









  



 
  

  