:root {
  --primary-color: #27C0D9;
  --secondary-color: #12889A;
  --accent-color: #e5feff;
}


body{
   font-family: 'Manrope', sans-serif;
}
