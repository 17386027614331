.additiveMaterialsHeader
{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 15px 10px 0 29px;
	background-color: var(--accent-color);
	width: 230px;
	height: 35px;
	border-radius: 5px	;
}

.additiveMaterialsHeader span{
	font-size: 16px;
	color: var(--secondary-color);
}
.tableContainer{
    display: flex;         
    justify-content: center; 
    align-items: center;     
	width: 95%;
	margin-top: 7px;
	margin-left: 1.8rem;
}

.table {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #ccc;
}

.table thead {
	position: sticky;
	top: 0;
	background: var(--secondary-color);
	color: #ffffff;
	z-index: 5;
}

.table thead th {
	font-weight: 100;
	font-size: 16px;
	height: 30px;
	border: none;
	text-align: left;
}

.tableContainer{
	display: block;
	max-height: 293px;
	overflow-y: auto;
}


.table th,
.table td {
	padding: 10px;
	border: 1px solid #ccc;
	text-align: left;
	border: none;
}

.table tbody tr {
	height: 25px;
	border-bottom: 1px solid #ccc;
	margin-left: 0.5rem;
} 

.table tbody tr:hover {
	background-color: var(--accent-color);
}


.currencySymbol {
	width: 30px;
	border: 1px solid #cdcdcd;
	border-radius: 2px 0 0 2px;
	border-right: none;
	display: flex;
	align-items: center;
	justify-content: center;
}


.inputCost {
	width: 150px;
	height: 22px;
	text-align: left;
	padding: 2px 2px 2px 5px;
	border-radius: 0 2px 2px 0;
	border: 1px solid #cdcdcd;
	background: transparent;
}

.bottomContainer {
	display: flex;
	margin-top: 2rem;
	gap: 1rem;
	position: absolute;
	bottom: 0rem;
	right: 2rem;
} 


.currencySymbol {
	width: 30px;
	border: 1px solid #cdcdcd;
	border-radius: 2px 0 0 2px;
	border-right: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1.5rem;
}


.inputCost {
	width: 85px;
	height: 22px;
	text-align: center;
	padding: 2px;
	border-radius: 0 2px 2px 0;
	border: 1px solid #cdcdcd;
}

.bottomContainer {
	display: flex;
	margin: 1rem;
	gap: 1rem;
	position: absolute;
	bottom: 0rem;
	right: 2rem;
} 

.costTableHeader{
	display: flex;
	align-items:center;
	gap: 10px;
	margin-left: 2rem;
}

.costTableInfoButton {
	margin-top: 4px;
	position: relative;
	display: inline-block;
  }
  
.costTableInfoButton .tooltipText {
	visibility: hidden;
	width: 100px;
	background-color: whitesmoke;
	color: var(--secondary-color);
	text-align: center;
	border-radius: 5px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	border: 1px solid var(--secondary-color);
	font-style: italic;
	font-size: 12px;
	margin-left: 5px;
	opacity: 1;
	transition: opacity 0.3s;
}
  
.costTableInfoButton:hover .tooltipText {
	visibility: visible;
	opacity: 1;
}