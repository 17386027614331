.container {
  display: flex;
  flex-direction: column;
 	align-items: center;
}

.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  position: relative;
}

.progressTrack {
  position: absolute;
 	top: 22%;
 	left: 1rem;
 	right: 1rem;
 	height: 1px;
 	background-color: #e9e9e9;
 	z-index: -1;
}

.stepContainer {
 	display: flex;
 	flex-direction: column;
 	align-items: center;
    justify-content: space-evenly;
	gap: 12px;
}

.progressStep {
 	width: 20px;
 	height: 20px;
 	border-radius: 50%;
 	background-color: #ffffff;
    border: 1px solid #d0d0d0;
 	display: flex;
 	justify-content: center;
 	align-items: center;
 	z-index: 1;
 	position: relative;
}

.dot {
 	width: 6px;
 	height: 6px;
 	border-radius: 50%;
}

.completed{
 	background-color: var(--primary-color);
    border: none;
}

.tick {
 	color: #fff;
    font-weight: 600;
 	font-size: 10px;
}

.current{
  border: 1px solid var(--primary-color);
}

.current .dot{
  background-color: var(--primary-color);
}


.stepLabel {
 	text-align: center;
 	font-size: 14px;
}


